import React from "react";
import { useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from 'axios'
import { serverLink} from "../url";
import { useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../common/loader";
import Chart from "react-google-charts";
import StatusChart from "react-google-charts";
import ApexChart from "react-apexcharts";
import {FaRegUser} from "react-icons/fa";
import {BiUserCheck} from "react-icons/bi";
import {TbUserCancel} from "react-icons/tb";
import {MdOutlineVerifiedUser} from "react-icons/md";
import {showAlert} from "../common/sweetalert/sweetalert";


const DashBoard = (props) => {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(true);
    const patientSerial = props.loginData[0]?.patientSerial;
    const [patientDetails, setPatientDetails] = useState(props.loginData[0])
    const imagePath = `${serverLink}public/uploads/patient_uploads/${patientDetails.passport}`;
    const placeholderImage = require('../img/male_avater.jpg');
    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [verifiedUsers, setVerifiedUsers] = useState(0);
    const [usersNotVerified, setUsersNotVerified] = useState(0);
    const [KYCVerified, setKYCVerified] = useState(0);
    const [stateDistribution, setStateDistribution] = useState([]);
    const [userGenderType, setUserGenderType] = useState([])

    const getData = async () => {
        setLoading(true)
        let record = {};
        await axios.get(`${serverLink}admin/report/dashboard/data`, token)
            .then(async (result) => {
                if (result.data.message === 'success'){
                    const user = result.data.user;
                    let gender = ['Male', 'Female'];

                    //Set count card
                    setTotalUsers(user.length)
                    setVerifiedUsers(user.filter(e=>e.is_email_validated === 1).length)
                    setUsersNotVerified(user.filter(e=>e.is_email_validated === 0).length)
                    setKYCVerified(user.filter(e=>e.is_KYC === 1).length)

                    //Initialize Data
                    let patient_state_data = [];
                    let genderData = [];
                    // StateData.map((e, i) => {
                    //     let count = user.filter(item => item.state === e.state).length
                    //     patientCount.push(count)
                    //     stateTypes.push(e.state)
                    //     patient_state_data.push([e.state, count]);
                    // })

                    //patient trend by gender
                    gender.map((e, i) => {
                        let count = user.filter(item => item.gender === e).length
                        genderData.push([e, count]);
                    })

                    //Set Chart Data
                    setApexChartOption({
                        ...apexChartOption,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: []
                        }
                    })
                    setApexSeries([{
                        name: "Count",
                        data: []
                    }])

                    setStateDistribution(patient_state_data)
                    setUserGenderType(genderData)

                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const genderData = [["Gender", "Count"], ...userGenderType];
    const patientGenderOptions = {
        title: "User Trend By Gender",
        pieHole: 0.4,
        is3D: true,
    };

    useEffect(() => { getData(); }, [])

    return (loading ? <PageLoader/> :
            <>
                <PageTitle title={["Home", "Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-3 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{totalUsers}</h2>
                                            <h6 className="text-cyan"> Registered Users</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><FaRegUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-3 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{verifiedUsers}</h2>
                                            <h6 className="text-cyan"> Verified Users</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><BiUserCheck size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-3 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{usersNotVerified}</h2>
                                            <h6 className="text-cyan"> Users Not Verified</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><TbUserCancel size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-3 col-6">
                            <div className="card border-bottom border-cyan">
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div>
                                            <h2>{KYCVerified}</h2>
                                            <h6 className="text-cyan"> KYC Verified</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-cyan display-6"><MdOutlineVerifiedUser size={25}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>User Registration Trend By Date</h4>
                                            {

                                                <ApexChart
                                                    options={apexChartOption}
                                                    series={apexSeries}
                                                    type="bar"
                                                    height={250}
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{borderRadius: '10px'}}>
                                <div className="card-body">
                                    {
                                        userGenderType.length > 0 &&
                                        <Chart
                                            chartType="PieChart"
                                            width="100%"
                                            height="310px"
                                            data={genderData}
                                            options={patientGenderOptions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(DashBoard);

