import React from "react";
import {setDashboardDetails, setLoginDetails, setPermissionDetails} from "../../action/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import {decryptData, encryptData, projectLogo, serverLink} from "../url"
import logo from '../../images/logo.png'
import { useEffect } from "react";

const ForgotPassword = (props) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        email_address: "",
    })
    useEffect(() => {
        props.setOnLoginDetails([]);
    }, [])

    const Login = async (e) =>  {
        e.preventDefault();
        setLoading(true);
        toast.info("Please wait while login you in...")

        if (formData.email_address === '') {
            toast.error("Please enter your registered email address");
            return false;
        }
        const token = "pa" + Math.floor(Math.random() * 999999999);
        toast.info("Please wait while trying to log you in...")
        await axios.patch(`${serverLink}admin/forget_password/add_token`, {email_address: formData.email_address, token: encryptData(token) })
            .then(res => {
                if (res.data.message === "success") {
                    toast.success('An email have been sent to your mailbox, please check and proceed.')
                } else if (res.data.message === "no email") {
                    toast.error('Sorry!, your email address is not registered')
                } else {
                    toast.error("Something went wrong. Please try again.")
                }
            })
            .catch(e => {
                console.log(e)
                toast.error("Please check your internet connection and try again!")
            })
    }


    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={{background: `url(../../assets/images/big/auth-bg.jpg) no-repeat center center`}}>
            <div className="auth-box">
                <div id="loginform">
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="db"><img src={projectLogo} alt="logo" /></span>

                    </div>
                    <h3 className="mb-3 text-center"><b>Forgot Password</b></h3>
                    <h5 className="font-medium m-b-20 text-center">Enter your email address to reset password</h5>
                    <div className="row">
                        <div className="col-12">
                            <form className="form-horizontal m-t-20" id="loginform" onSubmit={Login}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="ti-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control form-control-lg"
                                           placeholder="Email Address" id="email_address" onChange={onEdit}
                                           required aria-label="email_address" aria-describedby="basic-addon1"/>
                                </div>
                                <div className="form-group text-center">
                                    <div className="col-xs-12 p-b-20">
                                        <button className="btn btn-block btn-lg btn-info" type="submit">Submit</button>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="custom-control custom-checkbox text-center">
                                        {/*<Link to="/register" id="to-recover" className="text-dark" style={{fontSize: '18px'}}> Create an account</Link>*/}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="recoverform">
                    <div className="logo">
                        <span className="db"><img src="../../assets/images/logo-icon.png" alt="logo"/></span>
                        <h5 className="font-medium m-b-20">Recover Password</h5>
                        <span>Enter your Email and instructions will be sent to you!</span>
                    </div>
                    <div className="row m-t-20">
                        <form className="col-12" action="index.html">
                            <div className="form-group row">
                                <div className="col-12">
                                    <input className="form-control form-control-lg" type="email" required="" placeholder="Username"/>
                                </div>
                            </div>
                            <div className="row m-t-20">
                                <div className="col-12">
                                    <button className="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
        setOnDashboardData: (p) => {
            dispatch(setDashboardDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
